<template lang="pug">
v-container.accent2.pa-0.elevation-3.py-2.d-none.d-lg-flex(fluid)
  v-container.pa-0(fluid)(style="width: 90%")
    v-row(no-gutters)
      v-speed-dial.d-none.d-lg-flex.mt-n1(
        v-model="wasap",
        direction="bottom",
        transition="slide-y-transition",
        style="position: block"
      )
        template(v-slot:activator)
          .sdf(v-model="wasap")
            v-btn(
              color="whatsapp",
              dark,
              fab,
              width="50",
              height="50",
              style="z-index: 7"
            )
              v-icon(v-if="wasap", size="30") mdi-close
              v-icon(v-else, size="30") mdi-whatsapp
            v-btn.ml-n5(large, dark, color="whatsapp")
              .d-flex.flex-column.pl-5(style="line-height: 1.3")
                .d UNETE A NUESTRO GRUPOS
                .d DE IMPORTADORES
            v-icon.whatsapp--text(
              size="42",
              style="posicion: relative; left: -15px"
            ) mdi-play
        v-list.mr-16.elevation-5.pa-0
          v-list-item.text-h6.primary.accent2--text Seleccione su pais
          v-list-item.item-dial-wasap.py-0(
            v-for="(country, index) in countries",
            :key="index",
            style="border-bottom: 1px solid #eaeded"
          )
            a(:href="country.link", style="width: 100%; text-decoration: none")
              v-avatar.mr-3(size="30")
                img(:src="require(`@/assets/nav/${country.flag}`)", alt="Flag")
              | {{ country.name }}
      v-col.d-flex.flex-row.align-center.justify-right
        country-wasap(
          v-for="(country, index) in countries",
          :key="index",
          :country="country"
        )(
          style="width: 120px"
        )
      v-divider(vertical)
      v-col.d-flex.flex-row.align-center.justify-center
        .d-flex.flex-row.align-center
          v-btn(
            dark,
            style="font-size: 0.8vw",
            color="accent",
            @click="goFaq()"
          )
            v-icon.accent2--text mdi-chevron-right
            | COMO PARTICIPAR EN NUESTRAS IMPORTACIONES
</template>
<script>
import CountryWasap from "../nav/CountryWasap.vue";

export default {
  name: "ContainerWasap",
  components: {
    CountryWasap,
  },
  methods: {
    goFaq() {
      this.$router.push("/faq/f/active");
      window.scroll(0, 0);
    },
  },
  data: () => ({
    wasap: false,
    countries: [
      {
        name: "Panama",
        number: "+507 6219 4664",
        flag: "Flag_of_Panama.svg",
        link: "https://chat.whatsapp.com/BjCygW9T1yDKlwq0MLygac",
      },
      {
        name: "Peru",
        number: "+51 920 301 745",
        flag: "Flag_of_Peru.svg",
        link: "https://chat.whatsapp.com/LmVviqiKz9NHkVrEyU6Q8r",
      },
      {
        name: "Venezuela",
        number: "+58 212 763 2240",
        flag: "Flag_of_Venezuela.jpg",
        link: "https://chat.whatsapp.com/F0A0dOrWoYEFDCLjN06m9l",
      },
    ],
  }),
};
</script>
