<template lang="pug">
v-container.d-lg-none.pa-0.ma-0(
  fluid,
  style="position: fixed; z-index: 5; bottom: 0px; width: 100%"
)
  v-row.justify-center.whatsapp.py-1.accent2--text.text-subtitle-2(no-gutters)
    v-icon.px-1.accent2--text.accent2--text mdi-whatsapp
    | UNETE AL GRUPO DE IMPORTADORES
  v-row.accent2(no-gutters)
    v-col(v-for="(country, index) in countries", :key="index", cols="4")
      country-wasap(:country="country", style="width: 100%")
</template>
<script>
import CountryWasap from "../nav/CountryWasap.vue";
export default {
  name: "WasapMobile",
  components: {
    CountryWasap,
  },
  data: () => ({
    countries: [
      {
        name: "Panama",
        number: "+507 6219 4664",
        flag: "Flag_of_Panama.svg",
        link: "https://chat.whatsapp.com/BjCygW9T1yDKlwq0MLygac",
      },
      {
        name: "Peru",
        number: "+51 920 301 745",
        flag: "Flag_of_Peru.svg",
        link: "https://chat.whatsapp.com/LmVviqiKz9NHkVrEyU6Q8r",
      },
      {
        name: "Venezuela",
        number: "+58 212 763 2240",
        flag: "Flag_of_Venezuela.jpg",
        link: "https://chat.whatsapp.com/F0A0dOrWoYEFDCLjN06m9l",
      },
    ],
  }),
};
</script>
