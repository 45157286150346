import { render, staticRenderFns } from "./LogoutMenu.vue?vue&type=template&id=67788a98&scoped=true&lang=pug&"
import script from "./LogoutMenu.vue?vue&type=script&lang=js&"
export * from "./LogoutMenu.vue?vue&type=script&lang=js&"
import style0 from "./LogoutMenu.vue?vue&type=style&index=0&id=67788a98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67788a98",
  null
  
)

export default component.exports