<template>
  <div>
    <div class="mobile" style="position: fixed; bottom: 65px; right: -20px">
      <v-speed-dial v-model="fab" right="true">
        <template v-slot:activator>
          <v-btn v-model="fab" color="indigo" dark href="https://chat.whatsapp.com/LmVviqiKz9NHkVrEyU6Q8r"
            target="_blank" style="height: 50px; width: 280px">
            <v-icon style="margin: auto" v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-comment-question-outline </v-icon>
            <p style="margin: auto">¿Tienes preguntas?</p>
          </v-btn>
        </template>
        <!--  <v-btn
          dark
          target="_blank"
          color="green"
          x-large
          style="width: 250px"
          href="https://api.whatsapp.com/send?phone=584241153525&text=Buen+dia+vengo+de+su+web+pic-importadorescom+y+me+gustaria+mas+información+sobre+sus+servicios"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <p style="margin:auto" class="ml-5">Whatsapp <br />Venezuela</p>
          <img
            style="width:40px; height: 40px"
            width="10%"
            src="@/assets/venezuelaRound.svg"
            alt=""
          />
        </v-btn> -->
        <!--  <v-btn
          dark
          target="_blank"
          color="green"
          x-large
          style="width: 250px"
          href="https://api.whatsapp.com/send?phone=51920301745&text=Buen+dia+vengo+de+su+web+pic-importadorescom+y+me+gustaria+mas+información+sobre+sus+servicios"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <p style="margin:auto" class="ml-5">Whatsapp <br />Perú</p>
          <img
            style="width:40px; height: 40px"
            width="10%"
            src="@/assets/peruRound.svg"
            alt=""
          />
        </v-btn> -->
        <!-- <v-btn
          dark
          target="_blank"
          color="green"
          x-large
          style="width: 250px"
          href="https://api.whatsapp.com/send?phone=50762197664&text=Buen+dia+vengo+de+su+web+pic-importadorescom+y+me+gustaria+mas+información+sobre+sus+servicios"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <p style="margin:auto" class="ml-5">Whatsapp</p>
          <img
            style="width:40px; height: 40px"
            width="10%"
            src="@/assets/panamaRound.svg"
            alt=""
          />
        </v-btn> -->
        <!--  <v-btn
          dark
          color="blue darken-2"
          target="_blank"
          x-large
          style="width: 250px"
          href="https://calendly.com/asesoramiento-1/15min?back=1&month=2021-11"
        >
          <v-icon>mdi-calendar-clock</v-icon>
          <p style="margin:auto; text-align:center" class="ml-5">
            Agendar asesoria <br />
            gratuita
          </p>
        </v-btn> -->
      </v-speed-dial>
    </div>

    <div class="desktop" style="position: fixed; bottom: 30px; right: 20px">
      <v-speed-dial v-model="fab" right="true" open-on-hover="true">
        <template v-slot:activator>
          <v-btn v-model="fab" color="indigo" dark href="https://chat.whatsapp.com/LmVviqiKz9NHkVrEyU6Q8r"
            target="_blank" style="height: 60px; width: 250px">
            <v-icon style="margin: auto" v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-comment-question-outline </v-icon>
            <p style="margin: auto">¿Tienes preguntas?</p>
          </v-btn>
        </template>
        <!-- <v-btn
          dark
          target="_blank"
          color="green"
          x-large
          style="width: 250px"
          href="https://api.whatsapp.com/send?phone=584241153525&text=Buen+dia+vengo+de+su+web+pic-importadorescom+y+me+gustaria+mas+información+sobre+sus+servicios"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <p style="margin:auto" class="ml-5">Whatsapp <br />Venezuela</p>
          <img
            style="width:40px; height: 40px"
            width="10%"
            src="@/assets/venezuelaRound.svg"
            alt=""
          />
        </v-btn> -->
        <!-- <v-btn
          dark
          target="_blank"
          color="green"
          x-large
          style="width: 250px"
          href="https://api.whatsapp.com/send?phone=51920301745&text=Buen+dia+vengo+de+su+web+pic-importadorescom+y+me+gustaria+mas+información+sobre+sus+servicios"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <p style="margin:auto" class="ml-5">Whatsapp <br />Perú</p>
          <img
            style="width:40px; height: 40px"
            width="10%"
            src="@/assets/peruRound.svg"
            alt=""
          />
        </v-btn> -->
        <!-- <v-btn
          dark
          target="_blank"
          color="green"
          x-large
          style="width: 250px"
          href="https://api.whatsapp.com/send?phone=50762197664&text=Buen+dia+vengo+de+su+web+pic-importadorescom+y+me+gustaria+mas+información+sobre+sus+servicios"
        >
          <v-icon>mdi-whatsapp</v-icon>
          <p style="margin:auto" class="ml-5">Whatsapp</p>
          
        </v-btn> -->
        <!-- <v-btn
          dark
          color="blue darken-2"
          target="_blank"
          x-large
          style="width: 250px"
          href="https://calendly.com/asesoramiento-1/15min?back=1&month=2021-11"
        >
          <v-icon>mdi-calendar-clock</v-icon>
          <p style="margin:auto; text-align:center" class="ml-5">
            Agendar asesoria <br />
            gratuita
          </p>
        </v-btn> -->
      </v-speed-dial>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "WasapDial",
  data: () => ({
    wasap: false,
    countries: [
      {
        name: "Panama",
        number: "+50 762 194 664",
        flag: "Flag_of_Panama.svg",
        link: "https://chat.whatsapp.com/BjCygW9T1yDKlwq0MLygac",
      },
      {
        name: "Peru",
        number: "+51 920 301 745",
        flag: "Flag_of_Peru.svg",
        link: "https://chat.whatsapp.com/LmVviqiKz9NHkVrEyU6Q8r",
      },
      {
        name: "Venezuela",
        number: "+58 212 763 2240",
        flag: "Flag_of_Venezuela.jpg",
        link: "https://chat.whatsapp.com/F0A0dOrWoYEFDCLjN06m9l",
      },
    ],
  }),
  // watch: {
  //   wasap (val) {
  //     this.$store.commit('STATUS_SHOW_COVER', val)
  //   }
  // },
  methods: {},
  computed: {
    ...mapGetters(["getAsesorEnLinea"]),
  },
};
</script>
<style lang="scss">
.item-dial-wasap {
  cursor: pointer;
}

.item-dial-wasap:hover {
  background-color: #cfd8dc;
}

.mobile {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}

.desktop {
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
}
</style>
